/* PaginationComponent.css */
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.pagination a {
  color: white;;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  /* border: 1px solid #556ee6; */
  font-weight: 600;
  border-radius: 20px;
  margin: 0 5px;
  display: inline-block;
  min-width: 30px; /* Set a minimum width for the pagination buttons */
  text-align: center; /* Center text within the button */
}

.pagination a:hover {
  background-color: #556ee6;
  color: white;
}

.pagination .active a {
  background-color: #556ee6;
  color: white;
  pointer-events: none;
}

@media (max-width: 768px) {
  .pagination a {
    padding: 5px 10px;
    margin: 0 1px;
  }
}
