body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-dialog.full-modal {
  margin: 0!important;
  padding: 50px;
  background-color: #222736;
  border: 0!important;
}
.modal-dialog.full-modal > .modal-content{
  background-color: #222736;
  border: 0!important;
}

.offices-modal > .modal-content {
  background: #222736;
  border: 0;
}

.test-color {
  background-color: #2A3042;
}

input.account {
  background-color: #333849!important;
  border-width: 0!important;
  outline-color: #333849!important;
  color: #A6B0CF!important;
  padding: 8px!important;
}

input.setting-notification {
  width: 24px;
  height: 24px;
}

.MuiAccordion-root {
  background-color: #2a3042!important;
  color: #ffffff!important  ;
}

.MuiSvgIcon-root {
  background-color: #2a3042!important;
  color: #ffffff!important  ;
}

@media (max-width: 768px) {
  .MuiPaper-root {
    top: -50px!important;
  }
}
.demo-title__style {
  color: white;
  margin-left: 10px;
  font-size: 18px;
}
.w-10 {
  width: 10%!important;
}
.w-1 {
  width: 1%!important;
}
.w-2 {
  width: 2%!important;
}
.w-3 {
  width: 2%!important;
}
.w-4 {
  width: 4%!important;
}
.w-5 {
  width: 5%!important;
}
.w-6 {
  width: 6%!important;
}
.w-7 {
  width: 7%!important;
}
.w-8 {
  width: 8%!important;
}
.w-9 {
  width: 9%!important;
}
.w-10 {
  width: 10%!important;
}
.w-11 {
  width: 11%!important;
}
.w-12 {
  width: 12%!important;
}
.w-13 {
  width: 13%!important;
}
.w-14 {
  width: 14%!important;
}
.w-15 {
  width: 15%!important;
}
.w-16 {
  width: 16%!important;
}
.w-17 {
  width: 17%!important;
}
.w-53 {
  width: 53%;
}
.w-34 {
  width: 34%;
}
.w-64 {
  width: 64%;
}
.custom-mb-9 {
  margin-bottom: 9px;
}
.custom-mb-2 {
  margin-bottom: 0.625rem;
}
.custom-loader-w-20 {
  width: 80px !important;
}
.custom-h-9 {
  height: 34px !important;
}
.custom-loader-h-6 {
  height: 24px !important;
}
.custom-loader-h-260 {
  height: 260px !important;
}
.custom-w-2 {
  width: 30px !important;
}
.custom-w-12 {
  width: 50px !important;
}
.custom-w-24 {
  width: 100px !important;
}

.custom-loader__profile-menu {
  width: 60px !important;
  margin-left: 16px;
}
/* .custom-w-2 {
  width: 30px !important;
} */
.custom-h-2 {
  height: 30px;
}
.custom-h-
.custom-min-width-180 {
  min-width: 11.25rem;
}
.custom-max-width-17 {
  max-width: 70px;
}
.custom-max-width-545 {
  max-width: 34.063rem;
}

.custom-modal-outer__padding {
  padding: 10px !important;
}
.custom-modal-inner__padding {
  padding: 10px !important;
}

.custom-ps-8 {
  padding-left: 2rem !important;
}

.custome-z-50 {
  z-index: 50;
}
.custom-top-1 {
  top: 4px;
}

.custom-top-2 {
  top: 9px;
}

.custom-right-2 {
  right: 10px;
}

.custom-right-4 {
  right: 16px;
}

/* Custom Font Size */
.custom-fs-static-13 {
  font-size: 13px !important;
}
.custom-fs-10 {
  font-size: 10px;
}
.custom-fs-12 {
  font-size: 12px;
}
.custom-text-fs {
  font-size: 12px;
}
.custom-fs-12-to-14 {
  font-size: 12px;
}

.custom-tooltip .tooltip-inner {
  font-size: 14px;
}

.custom-badge-12-to-14 {
  font-size: 12px !important;
}
/* min height */
.min-h-90 {
  min-height: 90px;
}
.min-h-70 {
  min-height: 70px;
}

/* max-height */
.max-w-33 {
  max-width: 538px;
}

.button-ash {
  border-radius: 4px;
  background: #C4CBE2 !important;
}
.button-white {
  border-radius: 4px;
  background: #fff !important;
}

.bg-input .form-control {
  border-radius: 4px;
  background: #1E2330;
}

.bg-input .form-control:focus {
  background: #1E2330;
}

.custom-modal-title {
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 5px !important;
}

.agent-font-size {
  color: #FFF;
  text-align: center;
  /* font-family: Apercu Pro; */
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .test .apexcharts-legend-marker {
  border-radius: 0;
} */

.header-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.header-title-2 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.header-title-3 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.header-title-4 {
  font-size: 14px;
  font-weight: 400;
}

.header-title-5 {
  font-size: 14px;
  font-weight: 400;
}

.header-title-6 {
  font-size: 20px;
  font-weight: 400;
}

.header-subtitle{
  font-size: 16px;
  font-weight: 600;
}

.header-subtitle-2{
  font-size: 14px;
  font-weight: 500;
}

.page-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.page-subtitle {
  font-size: 16px;
  font-weight: 600;
}

.page-subtitle-2 {
  font-size: 12px;
}

.page-subtitle-3 {
  font-size: 12px;
  font-weight: 300;
}

.number-stats {
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
}
.number-stats-2 {
  font-weight: 500;
  font-size: 20px;
}

.number-stats-3 {
  font-size: 26px;
  font-weight: 700;
}

.number-stats-4 {
  font-size: 20px;
  font-weight: 700;
}

.number-stats-5 {
  font-size: 20px;
  font-weight: 700;
}
.number-stats-6 {
  font-size: 20px;
  font-weight: 700;
}

.number-stats-7 {
  font-size: 24px;
  font-weight: 700;
}
.number-stats-8 {
  font-size: 24px;
  font-weight: 500;
}
.number-stats-9 {
  font-size: 24px;
  font-weight: 500;
}
.number-stats-10 {
  font-size: 16px;
  font-weight: 700;
}
.number-stats-11 {
  font-size: 18px;
  font-weight: 700;
}
.number-stats-12 {
  font-size: 24px;
  font-weight: 700;
}
.number-stats-13 {
  font-size: 40px;
  font-weight: 700;
}
.cursor-pointer {
  cursor: pointer;
}

.segment-outer {
  margin-left: 5px;
  margin-right: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.apexcharts-tooltip {
  color: #fff !important;
  background-color: #2a3042 !important;
  border-color: #2a3042 !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: #32394e !important;
  border-bottom: 1px solid #32394e !important;
  color: #FFF;
}

.custom-recommendation-bg {
  background-color: #2B3041;
}
/* Form CSS */

.custom-form-header {
  font-size: 20px;
  font-weight: 600;
}
.custom-form-subheader {
  font-size: 16px;
  font-weight: 500;
}
.custom-width__yb {
  width: 200px !important;
}
.vertical-collapsed .hide-list-background-color {
  background-color: #2a3042 ;
}
.custom-order-6 {
  order: 6;
}

.custom-h-100-auto {
  height: 100%;
}
.custom-ps-1-to-3 {
  padding-left: .25rem !important;
}
.custom-recom-slide-number {
  top: 9.5%;
  right: 10%;
}
.custom-recom-slide-prev-arrow {
  left: -24% !important;
}
/* small screen */
@media (min-width: 576px) {

}

/* medium screen */
@media (min-width: 768px) {
  
}



/* large screen */
@media (min-width: 992px) {
  .segment-outer {
    margin: 40px;
  }
  .custom-recom-slide-prev-arrow {
    left: -16% !important;
  }
  .custom-recom-slide-number {
    top: 8.5%;
    right: 7%;
  }
  .custom-modal-outer__padding {
    padding: 30px !important;
  }

  .custom-modal-inner__padding {
    padding: 20px !important;
  }
  
  .custom-min-h-60 {
    min-height: 60px;
  }
  .custom-h-100-auto {
    height: auto;
  }
  .custom-loader__profile-menu {
    width: 168px !important;
  }
  .custom-width__yb {
    width: 100% !important;
  }
  .custom-ps-1-to-3 {
    padding-left: .75rem !important;
  }
  .header-title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
  .header-subtitle{
    font-size: 20px;
    font-weight: 600;
  }
  .header-subtitle-2{
    font-size: 16px;
    font-weight: 500;
  }
  .header-title-4 {
    font-size: 20px;
    font-weight: 400;
  }
  .header-title-5 {
    font-size: 16px;
    font-weight: 400;
  }
  .page-title {
    font-size: 24px;
  }
  .page-subtitle {
    font-size: 18px;
  }
  .page-subtitle-2 {
    font-size: 14px;
  }
  .page-subtitle-3 {
    font-size: 16px;
    font-weight: 300;
  }
  .custom-text-fs {
    font-size: 13px;
  }
  .custom-fs-12-to-14 {
    font-size: 14px;
  }
  .custom-badge-12-to-14 {
    font-size: 14px !important;
  }
  .number-stats {
    font-weight: 700;
    font-size: 34px;
    color: #ffffff;
  }
  .number-stats-2 {
    font-weight: 500;
    font-size: 40px;
  }
  .number-stats-3 {
    font-size: 32px;
    font-weight: 900;
  }
  .number-stats-4 {
    font-size: 40px;
    font-weight: 700;
  }
  .number-stats-5 {
    font-size: 32px;
    font-weight: 900;
  }
  .number-stats-6 {
    font-size: 32px;
    font-weight: 900;
  }
  .number-stats-7 {
    font-size: 34px;
    font-weight: 700;
  }
  .number-stats-8 {
    font-size: 36px;
    font-weight: 500;
  }
  .custom-mw-685 {
    max-width: 685px;
  }
  .custom-recommendation-bg {
    background-color: #2A3042;
  }
   
  .custom-parent--row >.custom-column:nth-child(odd) {
    /* Styles for odd columns */
   padding-right: .5rem !important;
  }
  .custom-parent--row >.custom-column:nth-child(even) {
    /* Styles for odd columns */
    padding-left: .25rem!important;
  }
}

/* extra large screen */
@media (min-width: 1200px) {
  
}
.custom-pie-chart .apexcharts-legend-series {
  text-align: end;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(34, 39, 54); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(51, 57, 76); 
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background: rgb(34, 39, 54);
}

.custom-legend-bg .apexcharts-legend-series {
  background-color: #454D5E;
  padding: 6px;
  border-radius: 4px;
}
.custom-legend-bg .apexcharts-legend-series:hover {
  background-color: #64748b;
  /* #4a505f */
}
.shimmer tbody {
  position: relative;
}

.shimmer__block {
  position: relative;
  background-color: #f44336; /* Red background color */
  opacity: 0.7;
  overflow: hidden;
}

.shimmer__block::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.6) 50%, transparent 100%);
  animation: shimmerAnimation 4s linear infinite;
}

@keyframes shimmerAnimation {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
}



/* Fix the table header and first column */

@media (max-width: 767px) {
  .scrollable-body {
    overflow-y: auto;
    height: 300px !important;
  }
  .scrollable-body-2 {
    overflow-y: auto;
    height: 200px !important;
  }
  .fixed-header thead,
  .fixed-column tbody {
    position: sticky;
    top: -6px;
  }

  .fixed-header thead,
  .fixed-column tr {
    /* background-color: #fff; */
    z-index: 100;
  }

  .fixed-column td:first-child, th:first-child {
    position: sticky;
    left: -6px;
    z-index: 101;
  }
}

.card-loader {
  position: relative;
  padding: 20px;
}

.loader-circle {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #556ee6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(50%, -50%) rotate(0deg); }
  100% { transform: translate(50%, -50%) rotate(360deg); }
}

.custom-tooltip-container {
  position: relative;
  display: inline-block;
}
.custom-tooltip-wrapper {
  visibility: hidden;
  min-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 999;
  bottom: 125%;
  left: 50%;
  margin-left: -102px;
  opacity: 0;
  transition: opacity 0.3s;
}
.custom-tooltip-container:hover .custom-tooltip-wrapper {
  visibility: visible;
  opacity: 1;
}
.custom-tooltip-wrapper::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.custom-popover .popover {
  background: #454D5E !important;
}
.custom-popover .popover-body {
  padding: .5rem;
}

.custom-table-apexchart .apexcharts-gridline {
  stroke: #A6B0CF !important;
}

/* Recommendation Page start */
.leaderboard-section {
  background-image: url('./assets/images/leaderboard-bg.png');
  background-size: cover; /* Ensures the image covers the entire section */
  /* object-fit: contain;
  object-position: center; */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  padding: 18px; /* Optional: Add padding for better content layout */
  color: white; /* Optional: Adjust text color for contrast */
}

.leaderboard-tab-container {
  position: relative;
  overflow: hidden;
}

.leaderboard-tab-button {
  cursor: pointer;
  z-index: 2; /* Ensure the text stays above the slider */
}

.leaderboard-slider-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: #1640d6;
  border-radius: 20px;
  transition: all 1000ms ease-in-out; /* Smooth transition */
}

.leaderboard-tab-button.active {
  color: white;
}

/* Recommendation Page end */